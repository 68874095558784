import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Segmented Bar"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Modules"
          tierThree="Segmented Bar"
        />
        <PageNavigation
          links={[
            'Behavior',
            'View Modes',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Related',
            'Platform Support',
          ]}
        />
        <Section title="Behavior">
          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            The segmented bar allows single or multi selection when clicking or
            tapping on individual bar segments. When selected, the bar segment
            and text turn blue and the percentage value is replaced with a check
            mark.
          </Paragraph>
        </Section>

        <Section title="View Modes">
          <Paragraph>
            Segmented bars can be viewed in the <strong>collapsed</strong> and{' '}
            <strong>preview</strong>{' '}
            <Link href="../../data-card/design#View%20Modes">
              view modes for data cards
            </Link>
            .
          </Paragraph>
          <SectionSubhead>Collapsed</SectionSubhead>
          <Paragraph>
            When displayed in the <strong>collapsed</strong> view mode, the
            segmented bar omits labels and percentages and acts as a
            mini-preview.
          </Paragraph>
          <List type="unordered">
            <li>
              <strong>Mobile</strong>: When tapping the mini-segmented bar, the
              view mode should switch to <strong>preview</strong> in order to
              make selection easier for the user. The selected segment within
              the bar should be selected and displayed before switching to{' '}
              <strong>preview</strong> mode.
            </li>
            <li>
              <strong>Web</strong>: When hovering or focusing on a bar segment,
              tooltips are displayed which include the label value of that
              segment. On click, the bar segment should be selected.
            </li>
          </List>
          <SectionSubhead>Preview</SectionSubhead>
          <Paragraph>
            In the <strong>preview</strong> view mode, the full segmented bar is
            always displayed.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Module System</SectionSubhead>
          <Paragraph>
            Segmented bars are part of the module system, and should only be
            used within data cards. Segmented bars should be used to filter
            content using multiple data set options.{' '}
            <strong>
              Segmented bars should be used to filter content between two and
              four data sets
            </strong>{' '}
            which are meant to be viewed separately.
          </Paragraph>
          <DontDo
            dontText="use segmented bars outside of data cards."
            doText="use segmented bars within the module system."
            imgFilename="segmentedbar-modules"
          />
          <DontDo
            dontText="display a long, complex set of data in segmented bars."
            doText="choose segmented bars to display smaller, and more common, data sets."
            imgFilename="segmentedbar-length"
          />
          <DontDo
            dontText="indicate empty data segments can be filtered."
            doText="display no data segments alongside others."
            imgFilename="segmentedbar-empty"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            The labels on each bar segment should be as short as possible
            without becoming arbitrary or difficult to understand. Ideally, bar
            segment{' '}
            <strong>labels should be between one and five characters</strong>.
            Longer labels are acceptable as long as there are a limited number
            of bar segments that allow the characters to be read without running
            into adjacent bars.
          </Paragraph>
          <DontDo
            dontText="use verbose labels in bar segments."
            doText="write concise, logical labels in bar segments."
            imgFilename="segmentedbar-labels"
          />
        </Section>

        <Section title="Accessibility">
          <SectionSubhead>
            Use descriptions when the label isn't enough
          </SectionSubhead>
          <Paragraph>
            Sometimes the button label alone isn't enough to properly convey the
            meaning intended. For example, a segmented bar for “Ball Carriers”
            might have labels that include the players' numbers, but omit the
            name for space. By adding their full names as descriptions, we can
            provide that extra information via{' '}
            <Link
              href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
              isExternals>
              ARIA attributes
            </Link>{' '}
            for screen readers and tooltips for all other users.
          </Paragraph>
          <DontDo
            dontText="use tooltips to provide unnecessary information or directions."
            doText="use tooltips to display a concise description when labels could be misunderstood."
            imgFilename="segmentedbar-description"
          />
          <DontDo
            dontText="omit tooltips in collapsed view mode."
            doText="include the clip count in parenthesis after the description or label in the tooltip."
            imgFilename="segmentedbar-tooltip"
          />

          <SectionSubhead>Keyboard support</SectionSubhead>
          <Paragraph>
            Tab navigation is fully supported by default. Users can press{' '}
            <KeyboardShortcut>enter</KeyboardShortcut> to toggle the selection
            of an item.
          </Paragraph>

          <AccessibilityAuditComponentResultsPartial componentName="Modules / Segmented Bar" />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../data-card/design">Data Card</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../card-group/design">Card Group</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design#Choosing%20a%20Visualization">
              Data Visualizations
            </Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3>
            <Link href="../../../../guidelines/accessibility/design">
              Accessibility
            </Link>
          </h3>
        </Section>

        <Section title="Platform Support">
          <Paragraph>
            The segmented bar is available for React and React Native.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
